<template>
  <div>
    <Preloader v-if="!dataLoaded" on-top/>
    <KpiNmhFilter v-else @search="fetch" />
    <div v-if="dataLoaded" class="card">
      <div class="card-body">
        <button
          v-for="(brand, key) in brands" :key="`filter_${key}`"
          class="btn btn-secondary m-b-5 m-r-5"
          :class="{ active: brand.filtered }"
          @click="filterBrand(brand)"
        >
          {{ brand.name }}
        </button>
        <button class="btn btn-success fa-pull-right" @click="resetBrandFilter">
          {{ $t('buttons.reset_filter') }}
        </button>
      </div>
    </div>
    <div v-if="dataLoaded" class="kpi-data-list">
      <div
        v-for="(brand, index) in filteredBrands"
        :key="`brand-${index}`"
        :data-competition-brands="brand.filteredCompetitionBrands"
        :data-brand-node-id="brand.nodeId"
        class="card"
      >
        <div class="card-body">
          <h3 class="text-themecolor">
            {{ brand.name }}
          </h3>
          <div
            v-for="(config, configIndex) in dataTableConfigs"
            :key="`brand-${index}-kpi-data-table-${configIndex}`"
          >
            <h4 class="kpi-first-column-label">
              {{ config.firstColumnLabels[propKeys.headerLabel] }}
              <Tooltip
                v-if="config.isPageViews"
                :fixed-width="false"
                :title="$t('kpiNmh.new.pageViews.tooltipInfo')"
              />
            </h4>
            <DataTable
              class="kpi-data-table"
              :data="getItemsForDataTable(brand.value, config)"
              :config="getDataTableConfig(config.firstColumnLabels)"
              :pagination="false"
              @on-cell-mouse-over="handleCellMouseOver"
              @on-cell-mouse-leave="handleCellMouseLeave"
            />
            <hr v-if="configIndex < dataTableConfigs.length - 1" />
          </div>
        </div>
      </div>
      <TooltipInfoText
        v-if="kpiTooltip.show"
        class="tooltip-info-text-component"
        :position="kpiTooltip.position"
        :title="kpiTooltip.text"
        text-align="left"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DataTable from '@/components/shared/DataTable'
import Preloader from '@/components/preloader/Preloader'
import KpiNmhFilter from '@/components/kpiNmh/KpiNmhFilter'
import Tooltip from '@/components/tooltip/Tooltip'
import TooltipInfoText from '@/components/tooltip/TooltipInfoText'

export default {
  name: 'KpiNmh',
  data () {
    return {
      dataLoaded: false,
      brands: [],
      firstRow: [],
      kpiTooltip: {
        show: false,
        text: '',
        position: {
          x: 0,
          y: 0
        }
      },
      propKeys: {
        headerLabel: '__headerLabel',
        firstColumn: '__firstColumn',
        budget: 'budget',
        realityVsKpi: 'realityVsKpi',
        pageViews: 'pageViews',
        pageViewsForecast: 'pageViewsForecast',
        videoViews: 'videoViews',
        videoViewsForecast: 'videoViewsForecast',
        uniqueUsersDailyAvg: 'uniqueUsersDailyAvg',
        timeSpentPerUserDailyAvg: 'timeSpentPerUserDailyAvg'
      }
    }
  },
  components: {
    DataTable,
    Preloader,
    KpiNmhFilter,
    Tooltip,
    TooltipInfoText
  },
  computed: {
    dataTableConfigs () {
      // all of 'sortedDataHubKeys' = more keys are always added in this.getFinalSortedKeys()
      const pageViews = {
        isPageViews: true,
        sortedDataHubKeys: [
          this.propKeys.pageViewsForecast, // see fillRealityVsKpi() - this needs to remain as first, skipping for the user, it's just for 'realityVsKpi' calculation
          this.propKeys.pageViews
        ],
        firstColumnLabels: {
          [this.propKeys.headerLabel]: this.$t('kpiNmh.new.pageViews.header'),
          [this.propKeys.budget]: this.$t('kpiNmh.new.pageViews.budget'), // budget -> pageViews
          [this.propKeys.pageViews]: this.$t('kpiNmh.new.pageViews.pageViews'), // data -> pageViews
          [this.propKeys.realityVsKpi]: this.$t('kpiNmh.new.pageViews.realityVsKpi') // (data -> pageViewsForecast / budget -> pageViews) * 100
        }
      }
      const realUsers = {
        sortedDataHubKeys: [
          this.propKeys.uniqueUsersDailyAvg, // see fillRealityVsKpi() - this needs to remain as first, skipping for the user, it's just for 'realityVsKpi' calculation
          this.propKeys.uniqueUsersDailyAvg
        ],
        firstColumnLabels: {
          [this.propKeys.headerLabel]: this.$t('kpiNmh.new.realUsers.header'),
          [this.propKeys.budget]: this.$t('kpiNmh.new.realUsers.budget'), // budget -> uniqueUsersDailyAvg
          [this.propKeys.uniqueUsersDailyAvg]: this.$t('kpiNmh.new.realUsers.uniqueUsersDailyAvg'), // data -> uniqueUsersDailyAvg
          [this.propKeys.realityVsKpi]: this.$t('kpiNmh.new.realUsers.realityVsKpi') // (data -> uniqueUsersDailyAvg / budget -> uniqueUsersDailyAvg) * 100
        }
      }
      const videoViews = {
        sortedDataHubKeys: [
          this.propKeys.videoViewsForecast, // see fillRealityVsKpi() - this needs to remain as first, skipping for the user, it's just for 'realityVsKpi' calculation
          this.propKeys.videoViews
        ],
        firstColumnLabels: {
          [this.propKeys.headerLabel]: this.$t('kpiNmh.new.videoViews.header'),
          [this.propKeys.budget]: this.$t('kpiNmh.new.videoViews.budget'), // budget -> videoViews
          [this.propKeys.videoViews]: this.$t('kpiNmh.new.videoViews.videoViews'), // data -> videoViews
          [this.propKeys.realityVsKpi]: this.$t('kpiNmh.new.videoViews.realityVsKpi') // (data -> videoViewsForecast / budget -> videoViews) * 100
        }
      }
      const averageTimeSpent = {
        isAverageTimeSpent: true,
        sortedDataHubKeys: [
          this.propKeys.timeSpentPerUserDailyAvg, // see fillRealityVsKpi() - this needs to remain as first, skipping for the user, it's just for 'realityVsKpi' calculation
          this.propKeys.timeSpentPerUserDailyAvg
        ],
        firstColumnLabels: {
          [this.propKeys.headerLabel]: this.$t('kpiNmh.new.averageTimeSpent.header'),
          [this.propKeys.budget]: this.$t('kpiNmh.new.averageTimeSpent.budget'), // budget -> timeSpentPerUserDailyAvg
          [this.propKeys.timeSpentPerUserDailyAvg]: this.$t('kpiNmh.new.averageTimeSpent.timeSpentPerUserDailyAvg'), // data -> timeSpentPerUserDailyAvg
          [this.propKeys.realityVsKpi]: this.$t('kpiNmh.new.averageTimeSpent.realityVsKpi') // (data -> timeSpentPerUserDailyAvg/ budget -> timeSpentPerUserDailyAvg) * 100
        }
      }
      return [
        pageViews,
        realUsers,
        videoViews,
        averageTimeSpent
      ]
    },
    filteredBrands () {
      const brands = this.brands.some(brand => brand.filtered)
        ? this.brands.filter(brand => brand.filtered)
        : this.brands

      return brands.map(brand => ({
        ...brand,
        filteredCompetitionBrands: this.filterCompetitionBrands(brand.competition)
      }))
    },
    months () {
      if (this.firstRow.length === 0) {
        return {}
      }
      return Object.keys(this.firstRow)
        .filter(key => key !== this.propKeys.firstColumn)
        .sort((a, b) => new Date(a) - new Date(b))
        .reduce((acc, month) => {
          acc[month] = moment(month).format('M/Y')
          return acc
        }, {})
    }
  },
  methods: {
    getDataTableConfig (firstColumnLabels) {
      return {
        fields: {
          [this.propKeys.firstColumn]: '', // this was moved to <h4 class="kpi-first-column-label">
          ...this.months
        },
        render: {
          [this.propKeys.firstColumn]: function (dataKey) {
            return firstColumnLabels[dataKey]
          }
        }
      }
    },
    getFinalSortedKeys (sortedKeys) {
      // sortedKeys.slice(1) - see 'dataTableConfigs' and 'sortedDataHubKeys' for each
      return [this.propKeys.budget, sortedKeys.slice(1), this.propKeys.realityVsKpi]
    },
    filterCompetitionBrands (brandIds) {
      return brandIds.slice(0, 3)
    },
    filterBrand (brand) {
      brand.filtered = !brand.filtered
      this.brands = [...this.brands]
    },
    resetBrandFilter () {
      this.brands = this.brands.map(brand => {
        brand.filtered = false
        return brand
      })
    },
    convertValuesToMap (brand, sortedKeys) {
      const valuesMap = {}
      this.fillDataHubData({ valuesMap, brand, sortedKeys })
      this.fillDataHubBudgetData({ valuesMap, brand, sortedKeys })
      return valuesMap
    },
    fillDataHubData ({ valuesMap, brand, sortedKeys }) {
      this.$store.getters['kpiNmhDataHub/list'].forEach(item => {
        if (item.brand === brand) {
          sortedKeys.forEach(key => {
            if (!valuesMap[key]) {
              valuesMap[key] = {}
            }
            valuesMap[key][item.month] = item[key]
          })
        }
      })
    },
    fillDataHubBudgetData ({ valuesMap, brand, sortedKeys }) {
      this.$store.getters['kpiNmhDataHub/settingsList'].forEach(item => {
        if (item.brand === brand) {
          sortedKeys.forEach(key => {
            if (!valuesMap[this.propKeys.budget]) {
              valuesMap[this.propKeys.budget] = {}
            }
            valuesMap[this.propKeys.budget][item.month] = item[key]
          })
          this.fillRealityVsKpi({ valuesMap, sortedKeys, month: item.month })
        }
      })
    },
    fillRealityVsKpi ({ valuesMap, sortedKeys, month }) {
      if (!valuesMap[this.propKeys.realityVsKpi]) {
        valuesMap[this.propKeys.realityVsKpi] = {}
      }
      // sortedKeys[0] - see 'dataTableConfigs' and 'sortedDataHubKeys' for each
      const pageViewsOrUniqueUsers = valuesMap[sortedKeys[0]][month]
      const budgetValue = valuesMap[this.propKeys.budget][month]
      if (budgetValue && pageViewsOrUniqueUsers && (typeof budgetValue === 'number') && (typeof pageViewsOrUniqueUsers === 'number')) {
        let value = pageViewsOrUniqueUsers / budgetValue
        const colorClass = value >= 1 ? 'text-green' : (value >= 0.7 ? 'text-warning' : 'text-danger')
        value = `<span class="${colorClass}">${this.$options.filters.formatPercentage(value)}</span>`
        valuesMap[this.propKeys.realityVsKpi][month] = value
      } else {
        valuesMap[this.propKeys.realityVsKpi][month] = 'N/A'
      }
    },
    convertValuesToTableRow (valuesMap, key, config) {
      const result = {
        [this.propKeys.firstColumn]: key
      }
      valuesMap[key] && Object
        .keys(valuesMap[key])
        .forEach(subKey => {
          const value = valuesMap[key][subKey]
          if (typeof value === 'number' && key !== this.propKeys.realityVsKpi) {
            const { formatNumber, formatMilliseconds } = this.$options.filters
            if (config.isAverageTimeSpent) {
              result[subKey] = formatMilliseconds(value * 1000)
            } else {
              result[subKey] = formatNumber(value)
            }
          } else {
            result[subKey] = value
          }
        })
      return result
    },
    getItemsForDataTable (brand, config) {
      const sortedRowsKeys = this.getFinalSortedKeys(config.sortedDataHubKeys)
      const valuesMap = this.convertValuesToMap(brand, config.sortedDataHubKeys)
      const items = sortedRowsKeys.map(key => this.convertValuesToTableRow(valuesMap, key, config))
      if (this.firstRow.length === 0) {
        this.firstRow = items[0]
      }
      return items
    },
    async fetch () {
      this.firstRow = []
      this.dataLoaded = false
      if (this.brands.length === 0) {
        await this.$store.dispatch('kpiNmhDataHub/fetchTree')
        this.brands = this.$store.getters['kpiNmhDataHub/tree']
      }
      await this.$store.dispatch('kpiNmhDataHub/fetch')
      await this.$store.dispatch('kpiNmhDataHub/fetchSettings')

      await this.$store.dispatch('kpiNmhDataHub/fetchCompetition')
      this.competitionList = this.$store.getters['kpiNmhDataHub/competitionList']

      this.dataLoaded = true
    },
    handleCellMouseOver (data) {
      const filteredTooltipData = this.filterTooltipData(data)
      let showTooltip = false
      let additionalTopContent = ''
      const tooltipPosition = {
        x: 0,
        y: 0
      }

      const targetRect = data.event.target.getBoundingClientRect()
      const targetContainer = data.event.target.closest('.kpi-data-list').getBoundingClientRect()

      const offsetX = 15
      const offsetY = 60
      tooltipPosition.x = targetRect.left + offsetX
      tooltipPosition.y = targetContainer.bottom - targetRect.top + offsetY

      const { formatNumber, formatMilliseconds } = this.$options.filters

      if (data.dataRowValue === this.propKeys.pageViews) {
        additionalTopContent = `
          <div class="kpi-tooltip-content__item">
            <span class="kpi-tooltip-content__item-value">${this.$t('kpiNmh.classicPageViews')}</span>
          </div>
        `
      }

      const tooltipDynamicContent = filteredTooltipData.map(item => {
        let metricValue = null

        switch (data.dataRowValue) {
          case this.propKeys.timeSpentPerUserDailyAvg:
            metricValue = formatMilliseconds(item[data.dataRowValue] * 1000)
            break
          default:
            metricValue = formatNumber(item[data.dataRowValue])
            break
        }

        if (!metricValue) return ''

        showTooltip = true

        return `
          <div class="kpi-tooltip-content__item">
            <span class="kpi-tooltip-content__item-title">${item.nodeName}</span>
            <span class="kpi-tooltip-content__item-value">${metricValue}</span>
          </div>
        `
      }).join('')

      const tooltipContent = `
        <span class="kpi-tooltip-content__title">
          ${this.$t('kpiNmh.valuesOfCompetition')}
        </span>
        ${additionalTopContent}
        ${tooltipDynamicContent}
        <span class="kpi-tooltip-content__footer">
          ${this.$t('kpiNmh.sourceGemius')}
        </span>
      `

      this.kpiTooltip = {
        show: showTooltip,
        text: tooltipContent,
        position: tooltipPosition
      }
    },
    handleCellMouseLeave (data) {
      this.kpiTooltip = {
        show: false,
        text: '',
        position: {
          x: 0,
          y: 0
        }
      }
    },
    filterTooltipData (data) {
      const competitionIds = data.competitionBrands.split(',').map(id => id.trim())
      // add also own brand id
      const brandNodeId = data.brandNodeId
      competitionIds.unshift(brandNodeId)

      const filteredItems = this.competitionList.filter(item =>
        item.month === data.dataColumnValue && competitionIds.includes(item.nodeId)
      )

      const filteredItemsMap = new Map(filteredItems.map(item => [item.nodeId, item]))

      return competitionIds.map(id => filteredItemsMap.get(id)).filter(item => item !== undefined)
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss">
.kpi-first-column-label {
  @include font(600 17px "Roboto");
  position: relative;
  margin-top: rem(15px);
  margin-bottom: rem(-15px);
  margin-left: rem(5px);
  z-index: 1;
}
.kpi-data-table {
  margin-left: rem(10px);
  .table-responsive {
    td:first-child {
      font-weight: 600;
      width: rem(380px);
      word-break: break-all;
    }
    .text-green {
      color: #3CB043;
    }
  }
}
.kpi-tooltip-content {
  &__title {
    @include font(600 14px "Roboto");
    margin-bottom: rem(2px);
  }
  &__footer {
    @include font(400 12px "Roboto");
    margin-top: rem(5px);
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(5px);
  }
  &__item-title {
    @include font(600 12px "Roboto");
  }
  &__item-value {
    @include font(400 12px "Roboto");
  }
}
</style>
